.block {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: calc(5px + env(safe-area-inset-left));
  font-size: var(--f7-block-font-size);
}

.project-images::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.project-images > img {
  max-height: 175px;
  width: auto;
  border-radius: 16px;
  scroll-snap-align: start start;
  margin-right: 10px;
  background-color: #f1f1f1;
}

@media (min-width: 768px) {
  .project-images img {
    margin-right: 24px;
  }
}

.project-images {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  white-space: nowrap;
  font-size: 0;
  scroll-padding-left: calc(16px + env(safe-area-inset-left));
}

.project-images:after {
  content: '';
  width: 1px;
  height: 1px;
  flex-shrink: 0;
}
