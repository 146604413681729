.lightbox-header ion-toolbar {
  --min-height: 56px;
  --border-width: 0px;
  --min-height: 56px;
  --background: #0d0d0d;
  --opacity: 0.9;
  /* color: #fff; */
}

ion-modal ion-header {
  display: block !important ;
}

ion-modal {
  --width: 100vw !important;
  --height: 100vh !important;
}

.swiper-pagination-bullet {
  background: #fff;
}

.swiper-zoom-container img {
  width: auto;
  height: 90%;
}
.swiper-zoom-container {
  text-align: center;
  max-height: 90%;
}

.image-caption {
  height: 10%;
  color: #fff;
  text-align: center;
}
