.thanks {
  color: black;
  text-align: center;
  font-size: 3rem !important;
  font-weight: bolder !important;
}
.message {
  text-align: center;
  font-size: 1.5rem !important;
}
