/* Global CSS */

.sticky-content {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
}
.focused-title {
  outline: 0;
}
#root-background {
  background: url(/assets/ami-bg.jpg) center center / cover;
  position: absolute;
  width: 110vw;
  height: 110vh;
  filter: blur(5px) saturate(7) grayscale(0.8);
}
#root-foreground .dark {
  background: rgba(19, 19, 19, 0.932) !important;
  position: absolute;
  width: 100%;
  height: 100%;
}
body {
  background: none !important;
}
ion-split-pane {
  max-width: 1900px;
  margin: 0 auto !important;
}
ion-content {
  --background: #ffffff00;
}
ion-split-pane {
  --border: none;
}
ion-item {
  --highlight-height: 0px;
}
/* Menu */
.menu-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
ion-split-pane .menu-left {
  --side-width: 275px;
  --side-min-width: 275px;
  --width: 275px;
}
.logo-img {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 5px;
}
.logo-img-toolbar {
  height: 45px;
}
.logo-img-toolbar img {
  height: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-top: 5px;
}
ion-menu-button,
ion-back-button {
  --color: #ffffff !important;
}
ion-menu ion-list#menu-list {
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
}
ion-menu ion-icon {
  margin-inline-start: 25px;
  margin-inline-end: 15px;
}
ion-menu ion-item {
  --border-radius: 25px;
  margin-bottom: 5px;
}
ion-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
ion-menu ion-list {
  padding: 20px 25px;
}
ion-menu ion-note {
  margin-bottom: 30px;
}
ion-menu ion-list-header,
ion-menu ion-note {
  padding-left: 10px;
}
#menuLeft ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  border-radius: 40px;
  --background: none;
  --color: var(--ion-color-primary);
  --background-hover: rgba(var(--ion-color-primary-rgb), 1);
  --background-hover-opacity: 0.15;
  --detail-icon-color: #fff;
  --detail-icon-opacity: 1;
}

#menuLeft ion-item.selected {
  --background: var(--ion-color-primary) !important;
  --color: #fff;
}
ion-menu ion-item ion-icon {
  color: var(--ion-color-primary);
  font-size: 32px;
}
ion-menu ion-item.selected ion-icon {
  color: #fff;
}
ion-menu ion-item ion-label {
  font-weight: 700;
  font-size: 24px !important;
}
ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}
#menu-list {
  background: #eaf8fa00 !important;
}
.footer-link-grid {
  left: 15px;
  flex: 0 0 72px;
  margin-top: auto;
  margin-bottom: -20px;
}
.footer-link-grid,
.footer-link-grid a {
  font-size: 13px;
  color: var(--ion-color-primary-shade);
}
.footer-link-grid ion-icon {
  font-size: 25px;
  color: var(--ion-color-primary-shade);
}
.footer-link-grid ion-button {
  font-size: 12px;
}
.copyright-wrap,
.copyright-wrap a {
  color: var(--ion-color-primary-shade);
  font-size: 13px;
}

/* Toolbar */
.alert-badge {
  --padding-start: 5px;
  --padding-end: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
}
@media only screen and (min-width: 1200px) {
  ion-header {
    display: none;
  }
  .ion-modal-custom ion-header {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .project-popover-button {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .project-list-responsive {
    display: none;
  }
}
ion-toolbar {
  --background: var(--ion-color-primary);
}
.header-md::after {
  display: none;
}
ion-title {
  margin-bottom: 3px;
  color: #fff;
}
.moon-icon {
  border-radius: 50%;
  padding: 7px;
  height: 15px;
  width: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.moon-icon-dark {
  background: var(--ion-color-primary);
  color: var(--ion-item-background, #fff);
}

/* Sidebar */
ion-split-pane .menu-right ion-content {
  --padding-top: 0px;
}
.searchbar-input.sc-ion-searchbar-md {
  border-radius: 20px;
}
.searchbar-input.sc-ion-searchbar-md {
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-position: left 8px center;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  left: 0px;
}
.gallery-wrap {
  margin: 20px;
}
#sidebar ion-item {
  --border-radius: 16px !important;
  margin-bottom: 5px;
}
#sidebar ion-item {
  --background: #f4f4f4 !important;
}
ion-menu ion-content,
ion-menu {
  --background: none !important;
}
@media only screen and (max-width: 1200px) {
  ion-menu ion-content,
  ion-menu {
    --background: #fff !important;
  }
}
.barricade {
  float: left;
  margin-left: 0px;
  margin-bottom: 0px;
}
.info-icon {
  font-size: 35px;
  margin-left: -5px;
}
.manatee-logo {
  max-width: 90%;
  margin: 0 auto;
}
.map-placeholder {
  height: 100%;
  width: auto;
}
.notification-button {
  position: relative;
  /* width: 42px; */
  /* top: 1px; */
  right: 10px;
  overflow: visible !important;
}
.notification-badge {
  background-color: red;
  position: absolute;
  top: -1px;
  right: 5px;
  min-height: 20px;
  border-radius: 100%;
}
.notification-badge::slot() {
  font-size: 30px;
}
.resource-links {
  margin-top: 15px !important;
}
.card-content-ios .about-text p {
  margin-bottom: 15px !important;
}
.about-text p:last-child {
  margin-bottom: 0px !important;
}
/* Alerts */
#alert-button {
  --inner-padding-start: 0px;
}
.alert-list-item {
  --padding-bottom: 10px !important;
}
ion-label.alert-label {
  text-overflow: ellipsis !important;
  white-space: unset !important;
  overflow: visible !important;
  overflow: initial !important;
  display: -webkit-box !important;
  -webkit-box-sizing: border-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  margin: 10px 8px 0px 0px !important;
}
@media screen and (min-width: 767px) {
  ion-modal.auto-height {
    --height: auto;
  }
  ion-modal.auto-height > .modal-wrapper > .ion-page {
    max-height: 90vh;
    position: relative;
    contain: content;
  }
}
ion-modal.auto-height {
  --max-width: 1000px;
}
.modal-wrapper > .ion-page {
  justify-content: unset;
}
ion-modal.auto-height > .modal-content-custom {
  overflow: auto;
  padding: 20px;
}

/* Project Detail Card */
.react-share__ShareButton {
  width: 100%;
}
ion-popover.alert-list-popover {
  --width: 340px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.alert-list-popover .popover-content {
  padding: 0px 10px;
  border-radius: 15px;
}
ion-popover.social-share-popover {
  --width: 210px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.social-share-popover .popover-content {
  padding: 0px 10px;
}
ion-popover.project-list-popover {
  --width: 270px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.project-list-popover .popover-content {
  padding: 1px 10px;
  border-radius: 18px;
}
ion-popover.more-menu-popover {
  --width: 200px;
  padding: 20px;
  --backdrop-opacity: 0.4;
}
ion-popover.more-menu-popover .popover-content {
  padding: 1px 10px;
  border-radius: 18px;
}
ion-card.project-detail ion-card-title {
  font-size: 28px !important;
}
.project-location-date-wrap-indesign {
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #c860dd1c;
  border: 1px solid #c860dd1f;
}
.project-location-date-wrap-inconstruction {
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #fff7e4;
  border: 1px solid #f5eedb;
}
.project-location-date-wrap-completed {
  width: calc(100% + 10px);
  padding: 13px 15px 9px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #5ed37325;
  border: 1px solid #50b86323;
}
.project-location {
  margin-bottom: 10px;
}
.project-location-date-wrap-indesign .project-start-date-title,
.project-location-date-wrap-indesign .project-end-date-title,
.project-location-date-wrap-indesign .project-location-title {
  color: var(--ion-color-indesign-shade);
  font-weight: 600;
}
.project-location-date-wrap-inconstruction .project-start-date-title,
.project-location-date-wrap-inconstruction .project-end-date-title,
.project-location-date-wrap-inconstruction .project-location-title {
  color: var(--ion-color-inconstruction-shade);
  font-weight: 600;
}
.project-location-date-wrap-completed .project-start-date-title,
.project-location-date-wrap-completed .project-end-date-title,
.project-location-date-wrap-completed .project-location-title {
  color: var(--ion-color-completed-shade);
  font-weight: 600;
}
.project-body-text {
  margin: 15px 0px;
}
.project-text p {
  margin-bottom: 15px;
}
.project-body-text h2 {
  color: #000 !important;
  font-size: 20px;
  line-height: 1.3;
  margin-top: 15px;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
.project-body-text h2.form-title {
  color: #000 !important;
  font-size: 20px;
  line-height: 1.3;
  margin-top: -20px !important;
  margin-bottom: 4px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
.about-body-text h2 {
  color: #000 !important;
  font-size: 22px;
  line-height: 1.3;
  margin-top: 20px;
  margin-bottom: 6px;
  font-weight: 600;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
.item .sc-ion-label-ios-h {
  white-space: unset;
}

/* Home */
.more-info-grid {
  margin-top: 15px;
}
.more-info-box {
  border-radius: 10px;
  background-color: #fff7e4;
  border: 1px solid #f5eedb;
  padding: 17px 20px;
  color: #692f02 !important;
}
.more-info-box p {
  margin-bottom: 0px !important;
}

/* Pages */
#Projects .content-wrap {
  padding: 15px 15px;
}
ion-list.project-list a,
ion-list.alert-list a {
  text-decoration: none;
}
ion-list.project-list {
  padding: 0px !important;
  margin-top: 10px;
}
ion-list.project-list.document-list {
  margin-top: 3px !important;
}
ion-list.project-list ion-item {
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  margin-bottom: 10px;
  --background: #f8f8f8;
  --inner-padding-start: 10px;
  --inner-padding-end: 5px;
  --border-radius: 13px !important;
  --border-color: #ededed;
  --border-width: 1px !important;
  --detail-icon-color: #666666;
  --background-hover-opacity: 0.03;
  --background-focused-opacity: 0.08;
}
ion-list.project-list ion-item {
  --padding-start: 12px;
}
ion-list.project-list ion-item.selected-inDesign {
  --detail-icon-color: var(--ion-color-indesign-tint);
  --border-color: #c860dd1f;
  --detail-icon-opacity: 1;
  --color: #000;
  --background: #c860dd1c;
}
ion-list.project-list ion-item.selected-inConstruction {
  --detail-icon-color: var(--ion-color-inconstruction);
  --border-color: #f5eedb;
  --detail-icon-opacity: 1;
  --color: #000;
  --background: #fff7e4;
}
ion-list.project-list ion-item.selected-completed {
  --detail-icon-color: var(--ion-color-completed-tint);
  --border-color: #50b86323;
  --detail-icon-opacity: 1;
  --color: #000;
  --background: #5ed37325;
}
.faq-item::part(native) {
  padding-inline-start: 5px !important;
}
ion-list.project-list ion-item {
  font-size: 16px;
  font-weight: 700;
}
ion-list.alert-list ion-item {
  font-size: 16px;
  font-weight: 500;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
  margin-bottom: 10px;
  --background: #f8f8f8;
  --inner-padding-start: 0px;
  --border-radius: 15px !important;
  --border-color: #e3e3e3;
  --border-width: 1px !important;
}
ion-card.alert-list > ion-card-header {
  padding-bottom: 10px;
}
ion-card.alert-list > ion-card-content.card-content-ios {
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  -webkit-padding-end: 20px;
  padding-inline-end: 20px;
  padding-bottom: 10px;
}
ion-list.alert-list ion-label {
  font-size: 16px !important;
  font-weight: 500;
}
ion-card.alert-list ion-card-subtitle {
  font-size: 16px;
}
ion-card.gallery > ion-card-header {
  padding-bottom: 10px;
}
ion-card.gallery > ion-card-content.card-content-ios {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
  padding-bottom: 0px !important;
}
ion-card.gallery.ios {
  margin-top: 15px !important;
}
ion-list.project-list ion-label {
  font-size: 14px !important;
  font-weight: 500;
  overflow: unset !important;
}
ion-card.more-info-card.ios {
  margin-top: 15px !important;
}
ion-spinner.cip-spinner {
  margin-left: -9px;
  margin-right: 8px;
  height: 23px;
  width: 23px;
}
.shadowContainer {
  padding: 10px 20px;
  box-shadow: #5555552d 0px 0px 10px 0px;
  border-radius: 15px;
}
.project-detail-right-top {
  font-size: 1.1rem;
  text-align: right;
}
.cip-detail-right-top {
  font-size: 1.2rem;
  text-align: right;
}
.project-detail-left-top {
  font-weight: 400;
  font-size: 1.1rem;
}
.home-img {
  width: 65%;
  max-width: 450px;
  float: right;
  margin-left: 30px;
  margin-bottom: 19px;
  margin-top: 0px;
}
.project-img {
  width: 50%;
  max-width: 300px;
  float: right;
  margin-left: 20px;
  margin-bottom: 19px;
  margin-top: 0px;
}
.image-gallery-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}
.image-gallery-thumbnail-image {
  border-radius: 15px !important;
}
@media only screen and (max-width: 487px) {
  .project-img,
  .home-img {
    width: 100%;
    max-width: none;
  }
}
@media only screen and (max-width: 768px) {
  .project-detail-right-top {
    font-size: 1.2rem;
    text-align: left;
  }
  .cip-detail-right-top {
    font-size: 1.2rem;
    text-align: left;
  }
}
#alertsPage ion-item {
  --border-radius: 16px;
  margin-bottom: 5px;
}
#alertsPage ion-item {
  --background: #f4f4f4 !important;
}

/* Content */
.read-more-link {
  text-decoration: underline;
  font-size: 0.9375rem;
  cursor: pointer;
}
.content-wrap {
  padding: 10px;
}
.jumbotron {
  border-radius: 0px !important;
  padding: 10px 20px !important;
  margin-bottom: 0px !important;
  background-color: #f5f5f5 !important;
}
ion-card {
  border-radius: 20px;
}
ion-card.ios {
  margin: 3px 2px;
}
ion-card.custom-margin-top.ios {
  margin-top: 15px !important;
}

ion-card.project-list > ion-card-header {
  padding-bottom: 5px;
}
ion-card.project-list > ion-card-content.card-content-ios {
  padding-bottom: 10px;
}
.card-content-ios p {
  font-size: 16px;
}
.card-content-ios {
  padding-top: 10px;
}
.card.news {
  background-color: #f8f8f8;
  border: 1px solid rgb(241 241 241);
  border-radius: 10px;
  margin: 5px 10px 20px 10px;
}
.news .card-title {
  color: #000;
}
.news .card-img,
.card-img-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.accordion {
  color: #000;
  margin-top: 10px;
  font-size: 14px;
}
.accordion .card {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}
.card-header {
  border-bottom: 1px solid #e6e6e6;
  background-color: rgb(248, 248, 248);
}
.list-md {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}
.alert-icon {
  margin-inline-start: 10px;
}
.webmap {
  height: 100%;
  width: 100%;
}
.esri-ui {
  border: 5px solid #fff;
  border-radius: 20px;
}
.esri-ui .esri-popup--is-docked {
  display: none !important;
}
.network-error {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.7) !important;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}
.fp-container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 1000;
}
.fp-container .fp-loader {
  top: 48%;
  left: 48%;
  z-index: 1005;
  position: absolute;
}
.webmap-dark {
  position: absolute;
  height: 100%;
  width: 100%;
}
.button-text-white {
  --color: #fff;
}

/* Modal */
.alert-modal > div {
  max-width: 800px !important;
}
.alert-img {
  border-radius: 20px;
}
.cip-modal > div {
  max-width: 600px;
}
.cip-modal .project-name {
  color: var(--ion-color-primary);
  font-size: 1.5rem !important;
  margin-bottom: 15px;
  margin-top: 0px;
}
.cip-modal table.status {
  margin: -5px -6px 10px -6px;
  border-collapse: separate;
  border-spacing: 6px 4px;
  table-layout: fixed;
  width: initial;
}
.cip-modal table.status td {
  background-color: #00734c;
  color: #ffffff;
  min-width: 0%;
  max-width: 100%;
  width: initial;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  padding: 4px 9px;
  border-radius: 9px;
}
.cip-modal table.data {
  border-collapse: separate;
  width: 100%;
  margin: 8px 0px;
  border-spacing: 0px 8px;
  table-layout: fixed;
}
.cip-modal table.data td {
  text-align: left;
  width: 50%;
  max-width: 100%;
  font-weight: normal;
  padding-left: 0px;
}
#alert-setting-modal {
  margin-top: -15px !important;
}
#alert-setting-modal > .modal-content > .modal-body {
  padding-bottom: 20px !important;
  padding-top: 0px !important;
}
.modal-content {
  border-radius: 20px !important;
  border-color: rgba(216, 216, 216, 0) !important;
  font-family: var(--ion-font-family, inherit) !important;
}
.modal-content h2 {
  font-size: 1.3rem !important;
}
.modal-header {
  background-color: #fff;
  color: #000;
  border: none !important;
  padding: 15px 20px 10px 20px !important;
}
.modal-backdrop.show {
  opacity: 0.7 !important;
}
.modal-body {
  padding: 0.5rem 1.4rem !important;
  color: #666666 !important;
  line-height: 1.4 !important;
  font-size: 16px !important;
}
.modal-footer {
  border-top: none !important;
}
.modal-title {
  font-size: 28px !important;
  font-weight: 700 !important;
}
.modal-header .close {
  position: relative;
  top: 5px;
  right: 5px;
  color: var(--ion-color-primary) !important;
  opacity: 0.9;
}
.modal-component {
  max-height: 80vh;
}
.ion-color-completed {
  --ion-color-base: var(--ion-color-completed);
  --ion-color-base-rgb: var(--ion-color-completed-rgb);
  --ion-color-contrast: var(--ion-color-completed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-completed-contrast-rgb);
  --ion-color-shade: var(--ion-color-completed-shade);
  --ion-color-tint: var(--ion-color-completed-tint);
}
.ion-color-indesign {
  --ion-color-base: var(--ion-color-indesign);
  --ion-color-base-rgb: var(--ion-color-indesign-rgb);
  --ion-color-contrast: var(--ion-color-indesign-contrast);
  --ion-color-contrast-rgb: var(--ion-color-indesign-contrast-rgb);
  --ion-color-shade: var(--ion-color-indesign-shade);
  --ion-color-tint: var(--ion-color-indesign-tint);
}
.ion-color-inconstruction {
  --ion-color-base: var(--ion-color-inconstruction);
  --ion-color-base-rgb: var(--ion-color-inconstruction-rgb);
  --ion-color-contrast: var(--ion-color-inconstruction-contrast);
  --ion-color-contrast-rgb: var(--ion-color-inconstruction-contrast-rgb);
  --ion-color-shade: var(--ion-color-inconstruction-shade);
  --ion-color-tint: var(--ion-color-inconstruction-tint);
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  /** primary **/
  --ion-color-primary: #004071;
  --ion-color-primary-rgb: 0, 64, 113;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003863;
  --ion-color-primary-tint: #1a537f;
  /** secondary **/
  --ion-color-secondary: #007db6;
  --ion-color-secondary-rgb: 0, 125, 182;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #006ea0;
  --ion-color-secondary-tint: #1a8abd;
  /** tertiary **/
  --ion-color-tertiary: #da682c;
  --ion-color-tertiary-rgb: 218, 104, 44;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #c05c27;
  --ion-color-tertiary-tint: #de7741;
  /** completed **/
  --ion-color-completed: #19822d;
  --ion-color-completed-rgb: 25, 130, 45;
  --ion-color-completed-contrast: #ffffff;
  --ion-color-completed-contrast-rgb: 255, 255, 255;
  --ion-color-completed-shade: #167228;
  --ion-color-completed-tint: #308f42;
  /** in design **/
  --ion-color-indesign: #b027cd;
  --ion-color-indesign-rgb: 176, 39, 205;
  --ion-color-indesign-contrast: #ffffff;
  --ion-color-indesign-contrast-rgb: 255, 255, 255;
  --ion-color-indesign-shade: #9b22b4;
  --ion-color-indesign-tint: #b83dd2;
  /** in construction **/
  --ion-color-inconstruction: #f56a00;
  --ion-color-inconstruction-rgb: 245, 106, 0;
  --ion-color-inconstruction-contrast: #000000;
  --ion-color-inconstruction-contrast-rgb: 0, 0, 0;
  --ion-color-inconstruction-shade: #d85d00;
  --ion-color-inconstruction-tint: #f6791a;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
